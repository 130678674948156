@tailwind base;
@tailwind components;
@tailwind utilities;

main {
  flex: 1;
  max-width: 1280px;
  margin: auto;
  min-height: 90vh;
  padding: 10px;
  font-family: "Figtree", sans-serif;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

::-webkit-scrollbar {
  width: 2px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #1d3465;
}

::-webkit-scrollbar-track {
  background-color: #627fb5;
}
